define("discourse/plugins/discourse-sakura-whispers/discourse/templates/sakura-whispers-leaderboard", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <SakuraWhispersNavigation />
  {{#if @model.length}}
    <div class="leaderboard-columns">
      <span>Rank</span>
      <span>Score</span>
    </div>
    {{#each @model as |r|}}
      <div class="leaderboard-row {{if r.user.current_user '--current-user'}} {{if r.is_worthy '--worthy'}}">
        <div class="leaderboard-row__rank rank-{{r.rank}}">{{r.rank}}</div>
        <div class="leaderboard-row__user clickable" data-user-card={{r.user.username}}>{{avatar r.user imageSize="large"}}{{r.user.name}}</div>
        <div class="leaderboard-row__score">{{r.score}}</div>
      </div>
    {{/each}}
  {{else}}
    <div class="empty-state">
      <p>The leaderboard is updated <strong>every night</strong> during the meetup.</p>
      <p>All votes from the <strong>previous day</strong> will be locked in 🔐.</p>
      <p>Please come back after the first <em>night</em> of the meetup 😉</p>
      <p>Good luck 🌸</p>
    </div>
  {{/if}}
  */
  {
    "id": "/jim0vto",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[41,[30,1,[\"length\"]],[[[1,\"  \"],[10,0],[14,0,\"leaderboard-columns\"],[12],[1,\"\\n    \"],[10,1],[12],[1,\"Rank\"],[13],[1,\"\\n    \"],[10,1],[12],[1,\"Score\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,1]],null]],null],null,[[[1,\"    \"],[10,0],[15,0,[29,[\"leaderboard-row \",[52,[30,2,[\"user\",\"current_user\"]],\"--current-user\"],\" \",[52,[30,2,[\"is_worthy\"]],\"--worthy\"]]]],[12],[1,\"\\n      \"],[10,0],[15,0,[29,[\"leaderboard-row__rank rank-\",[30,2,[\"rank\"]]]]],[12],[1,[30,2,[\"rank\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"leaderboard-row__user clickable\"],[15,\"data-user-card\",[30,2,[\"user\",\"username\"]]],[12],[1,[28,[35,4],[[30,2,[\"user\"]]],[[\"imageSize\"],[\"large\"]]]],[1,[30,2,[\"user\",\"name\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"leaderboard-row__score\"],[12],[1,[30,2,[\"score\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null]],[]],[[[1,\"  \"],[10,0],[14,0,\"empty-state\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"The leaderboard is updated \"],[10,\"strong\"],[12],[1,\"every night\"],[13],[1,\" during the meetup.\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"All votes from the \"],[10,\"strong\"],[12],[1,\"previous day\"],[13],[1,\" will be locked in 🔐.\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Please come back after the first \"],[10,\"em\"],[12],[1,\"night\"],[13],[1,\" of the meetup 😉\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Good luck 🌸\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"@model\",\"r\"],false,[\"sakura-whispers-navigation\",\"if\",\"each\",\"-track-array\",\"avatar\"]]",
    "moduleName": "discourse/plugins/discourse-sakura-whispers/discourse/templates/sakura-whispers-leaderboard.hbs",
    "isStrictMode": false
  });
});