define("discourse/plugins/discourse-sakura-whispers/discourse/routes/sakura-whispers-index", ["exports", "discourse/routes/discourse", "discourse/lib/ajax", "@ember-compat/tracked-built-ins"], function (_exports, _discourse, _ajax, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SakuraWhispersIndex extends _discourse.default {
    async model() {
      const data = await (0, _ajax.ajax)("/sakura-whispers.json");
      return data.map(r => ({
        user: r.user,
        locked: r.locked,
        statements: r.statements.map(s => new _trackedBuiltIns.TrackedObject(s))
      }));
    }
  }
  _exports.default = SakuraWhispersIndex;
});