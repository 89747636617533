define("discourse/plugins/discourse-sakura-whispers/discourse/routes/sakura-whispers-leaderboard", ["exports", "discourse/routes/discourse", "discourse/lib/ajax"], function (_exports, _discourse, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SakuraWhispersIndex extends _discourse.default {
    async model() {
      return await (0, _ajax.ajax)("/sakura-whispers/leaderboard.json");
    }
  }
  _exports.default = SakuraWhispersIndex;
});